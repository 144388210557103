<!--
 * ======================================
 * 说明： 年级组长
 * 作者： Silence
 * 文件： gradeteacher.vue
 * 日期： 2023/8/2 0:08
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Title title="任务管理"/>
      <div class="m-4">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="选择学期">
            <semesterSelect v-model="formInline.semester_id"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleLoadTaskInfo">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="m-2">
        <el-form ref="form" :model="formData" label-width="80px">
          <div class="task-setting-box">
            <el-form-item label="任务名称">
              <el-input v-model="formData.title"></el-input>
            </el-form-item>
            <el-form-item label="任务时间">
              <el-date-picker
                  v-model="formData.datetime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-tag v-if="formData.status === 1" type="success">已发布</el-tag>
              <el-tag v-else-if="formData.status === 2" type="danger">已关闭</el-tag>
              <el-tag v-else type="primary">待发布</el-tag>
            </el-form-item>
            <el-form-item label="任务模块">
              <el-cascader-panel :props="props" v-model="formData.content" :options="options"></el-cascader-panel>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button @click="handleTaskSubmit(0)">保存任务</el-button>
            <el-button type="primary" @click="handleTaskSubmit(1)">发布任务</el-button>
            <el-button type="success" @click="handleTaskSubmit(2)">关闭任务</el-button>
          </el-form-item>
        </el-form>
      </div>
    </Scrollbar>
  </div>
</template>
<script>
import options from "./options.js";
import semesterSelect from "./components/semesterSelect";

export default {
  name: '',
  components: {
    semesterSelect
  },
  props: {},
  data() {
    return {
      isDefault: true,  // 默认查询加载中的第一次
      title: '发布劳动任务',
      semesterOptions: [],
      ddd: [],
      props: {
        multiple: true,
      },
      formInline: {
        semester_id: '',
        grade:'',
      },
      formData: {
        title: '',
        content: [],
        datetime: '',
        status: 0
      },
      options: options
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {
    "formInline.semester_id"(value) {
      if (value && this.isDefault) {
        this.isDefault = false
        //当选项完成了执行
        this.handleLoadTaskInfo()
      }
    }
  },

  created() {
  },
  async mounted() {
    //加载选项
    await this.handleLoadBlackOptions();
    // 加载年级
    await this.handleLoadGrade();
  },
  methods: {
    handleSearch() {
    
    },
    /**
     * 处理界面功能
     */
    handleTaskSubmit(status) {
      let {semester_id , grade} = this.formInline;
      this.formData.semester_id = semester_id;
      this.formData.status = status
      this.formData.grade = grade
      return this.$cloud.post("task/save", this.formData).then(res => {
        console.log("res", res)
        switch ( status ) {
          case 0:
            this.$message.success('保存成功')
            break
          case 1:
            this.$message.success('发布成功')
            break
          case 2:
            this.$message.success('关闭成功')
            break
        }
      })
    },
    
    /**
     * 处理界面功能
     */
    handleLoadBlackOptions() {
      return this.$cloud.get("block/options",).then(res => {
        console.log("需要的任务", res)
        this.options = res;
      })
    },
  
    async handleLoadGrade(){
      await this.$cloud.get("grade/get/now").then(res=>{
        this.formInline.grade= res
      })
    },
    
    handleLoadTaskInfo() {
      let {semester_id} = this.formInline;
      return this.$cloud.get("task/detail", {
        semester_id: semester_id
      }).then(res => {
        if(typeof res === 'object'){
          this.formData = res;
        }else{
          this.formData= {
            title: '',
            content: [],
            datetime: '',
            status: 0
          }
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.task-setting-box{
  max-height: calc(100vh - 390px);
  overflow: hidden;
  overflow-y: auto;
}
</style>
